import React from "react";
import { connect } from "react-redux";

import { getDefaultTheme } from "@ax/helpers";
import { Loading, BrowserContent } from "@ax/components";
import { FormContent, ILanguage, IRootState, ISite, ISocialState } from "@ax/types";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { formsActions } from "@ax/containers/Forms";
import { findByEditorID } from "@ax/forms";
import { useOnMessageReceivedFromOutside, useURLSearchParam } from "@ax/hooks";

import * as S from "./style";

const FramePreview = (props: IProps) => {
  const {
    pageContent,
    formContent,
    socials,
    cloudinaryName,
    isLoading,
    currentSiteInfo,
    siteLangs,
    globalLangs,
    setSelectedPageContent,
    setSelectedFormContent,
    setEditorContent,
    setFormContent,
    deleteModule,
    duplicateModule,
  } = props;

  const isPreview = useURLSearchParam("preview");
  const isDisabled = useURLSearchParam("disabled");
  const type = useURLSearchParam("type");
  document.body.classList.add("preview");
  const isForm = type === "form";
  const setSelectedContent = isForm ? setSelectedFormContent : setSelectedPageContent;
  const setContent = isForm ? setFormContent : setEditorContent;
  const content = isForm ? formContent : pageContent;
  const { canonicalSite, language, pageLanguages, header, footer } = content;

  useOnMessageReceivedFromOutside(setContent, setSelectedContent);

  const selectEditorID = (
    selectedComponent: { editorID: number; component: any; type: string; parentEditorID: number },
    parentComponent: string | undefined | null,
    e: React.SyntheticEvent
  ) => {
    const { element } = findByEditorID(content, selectedComponent.parentEditorID);
    element && e.stopPropagation();

    const { editorID } = selectedComponent;

    const isNavigationModule = ["header", "footer"].includes(selectedComponent.type);

    if (isPreview === "false" && (isDisabled === "false" || isNavigationModule)) {
      window.parent.postMessage({ type: "module-click", message: editorID }, "*");
      setSelectedContent && setSelectedContent(editorID);
    }
  };

  const selectHoverEditorID = (editorID: number, parentEditorID: number) => {
    const { parent } = findByEditorID(content, parentEditorID);
    if (parent && parent.parentEditorID === 0) {
      window.parent.postMessage({ type: "module-scroll", message: editorID }, "*");
    }
  };

  const globalTheme = getDefaultTheme();
  const theme = currentSiteInfo ? currentSiteInfo.theme : globalTheme;
  const langs = currentSiteInfo ? siteLangs : globalLangs;
  const siteID = currentSiteInfo ? currentSiteInfo.id : canonicalSite;
  const renderer = isForm ? "forms" : isPreview === "true" ? "preview" : "editor";

  const deleteModuleSelected = (editorID: number) => {
    window.parent.postMessage({ type: "module-delete", message: editorID }, "*");
    setSelectedContent && setSelectedContent(0);
    deleteModule([editorID]);
  };

  const duplicateModuleSelected = (editorID: number) => {
    window.parent.postMessage({ type: "module-duplicate", message: editorID }, "*");
    duplicateModule([editorID]);
  };

  const moduleActions = {
    deleteModuleAction: deleteModuleSelected,
    duplicateModuleAction: duplicateModuleSelected,
  };

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper ref={(ref: any) => ((window as any).browserRef = ref)}>
      <BrowserContent
        cloudinaryName={cloudinaryName}
        theme={theme}
        socials={socials}
        siteLangs={langs}
        selectEditorID={selectEditorID}
        siteID={siteID}
        isPage={true}
        content={content}
        header={currentSiteInfo && header}
        footer={currentSiteInfo && footer}
        languageID={language}
        pageLanguages={pageLanguages}
        moduleActions={moduleActions}
        renderer={renderer}
        selectHoverEditorID={selectHoverEditorID}
      />
    </S.Wrapper>
  );
};

interface IProps {
  pageContent: any;
  formContent: any;
  socials: ISocialState;
  cloudinaryName: string | null;
  currentSiteInfo: ISite | null;
  siteLangs: ILanguage[];
  globalLangs: ILanguage[];
  isLoading: boolean;
  setSelectedPageContent(editorID: number): void;
  setSelectedFormContent(editorID: number): void;
  setEditorContent(editorContent: Record<string, unknown>): void;
  setFormContent(formContent: FormContent | null): void;
  deleteModule(editorID: number[]): void;
  duplicateModule(editorID: number[]): number;
}

const mapStateToProps = (state: IRootState) => ({
  pageContent: { ...state.pageEditor.editorContent },
  formContent: { ...state.forms.formContent },
  socials: state.social,
  cloudinaryName: state.app.globalSettings.cloudinaryName,
  currentSiteInfo: state.sites.currentSiteInfo,
  siteLangs: state.sites.currentSiteLanguages,
  globalLangs: state.app.globalLangs,
  isLoading: state.app.isLoading,
});

const mapDispatchToProps = {
  setSelectedPageContent: pageEditorActions.setSelectedContent,
  setSelectedFormContent: formsActions.setSelectedContent,
  setEditorContent: pageEditorActions.setEditorContent,
  setFormContent: formsActions.setFormContent,
  deleteModule: pageEditorActions.deleteModule,
  duplicateModule: pageEditorActions.duplicateModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(FramePreview);

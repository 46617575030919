import {
  FormCategoriesOrderParams,
  FormContent,
  FormState,
  GetFormsParams,
  PostFormCategoryParams,
  PutFormCategoryParams,
} from "@ax/types";
import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_FORMS: {
    ...template,
    endpoint: ["/site/", "/forms"],
    method: "GET",
  },
  GET_FORM: {
    ...template,
    endpoint: "/form/",
    method: "GET",
  },
  CREATE_FORM: {
    ...template,
    endpoint: "/form",
    method: "POST",
  },
  UPDATE_FORM: {
    ...template,
    endpoint: "/form/",
    method: "PUT",
  },
  DELETE_FORM: {
    ...template,
    endpoint: "/form/",
    method: "DELETE",
  },
  DELETE_FORM_BULK: {
    ...template,
    endpoint: "/form/bulk",
    method: "DELETE",
  },
  UPDATE_FORM_STATE: {
    ...template,
    endpoint: ["/form/", "/state/"],
    method: "PUT",
  },
  GET_FORM_CATEGORIES: {
    ...template,
    endpoint: ["/site/", "/form/categories/"],
    method: "GET",
  },
  CREATE_FORM_CATEGORY: {
    ...template,
    endpoint: "/form/category",
    method: "POST",
  },
  UPDATE_FORM_CATEGORY: {
    ...template,
    endpoint: "/form/category/",
    method: "PUT",
  },
  DELETE_FORM_CATEGORY: {
    ...template,
    endpoint: "/form/category/",
    method: "DELETE",
  },
  DELETE_FORM_CATEGORY_BULK: {
    ...template,
    endpoint: "/form/category/bulk",
    method: "DELETE",
  },
  ORDER_FORM_CATEGORY: {
    ...template,
    endpoint: "/form/category/order",
    method: "PUT",
  },
  FORM_AI_TRANSLATION: {
    ...template,
    endpoint: "/translations/form/",
    method: "POST",
  },
};

const getForms = async (siteID: number | "global", params: GetFormsParams) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_FORMS;

  const { page, itemsPerPage, pagination } = params;

  SERVICES.GET_FORMS.dynamicUrl = `${host}${prefix}${siteID}${suffix}?page=${page}&itemsPerPage=${itemsPerPage}&pagination=${pagination}`;
  return sendRequest(SERVICES.GET_FORMS);
};

const getForm = async (formID: number) => {
  const { host, endpoint } = SERVICES.GET_FORM;
  SERVICES.GET_FORM.dynamicUrl = `${host}${endpoint}${formID}`;

  return sendRequest(SERVICES.GET_FORM);
};

const createForm = async (data: any) => sendRequest(SERVICES.CREATE_FORM, { ...data });

const updateForm = async (formID: number, data: any) => {
  const { host, endpoint } = SERVICES.UPDATE_FORM;
  SERVICES.UPDATE_FORM.dynamicUrl = `${host}${endpoint}${formID}`;

  return sendRequest(SERVICES.UPDATE_FORM, { ...data });
};

const deleteForm = async (formID: number) => {
  const { host, endpoint } = SERVICES.DELETE_FORM;

  SERVICES.DELETE_FORM.dynamicUrl = `${host}${endpoint}${formID}`;

  return sendRequest(SERVICES.DELETE_FORM);
};

const updateFormState = async (formID: number, state: FormState) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UPDATE_FORM_STATE;

  SERVICES.UPDATE_FORM_STATE.dynamicUrl = `${host}${prefix}${formID}${suffix}${state}`;
  return sendRequest(SERVICES.UPDATE_FORM_STATE);
};

const deleteFormBulk = async (formIds: number[]) => {
  return sendRequest(SERVICES.DELETE_FORM_BULK, { formIds });
};

const getFormTranslation = async (data: FormContent, langID: number) => {
  const { host, endpoint } = SERVICES.FORM_AI_TRANSLATION;
  SERVICES.FORM_AI_TRANSLATION.dynamicUrl = `${host}${endpoint}${langID}`;

  return sendRequest(SERVICES.FORM_AI_TRANSLATION, { ...data });
};

const getFormCategories = async (siteID: number | "global", type: string) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_FORM_CATEGORIES;

  SERVICES.GET_FORM_CATEGORIES.dynamicUrl = `${host}${prefix}${siteID}${suffix}${type}`;
  return sendRequest(SERVICES.GET_FORM_CATEGORIES);
};

const createFormCategory = async (data: PostFormCategoryParams) =>
  sendRequest(SERVICES.CREATE_FORM_CATEGORY, { ...data });

const updateFormCategory = async (categoryID: number, data: PutFormCategoryParams) => {
  const { host, endpoint } = SERVICES.UPDATE_FORM_CATEGORY;
  SERVICES.UPDATE_FORM_CATEGORY.dynamicUrl = `${host}${endpoint}${categoryID}`;

  return sendRequest(SERVICES.UPDATE_FORM_CATEGORY, { ...data });
};

const deleteFormCategory = async (categoryID: number) => {
  const { host, endpoint } = SERVICES.DELETE_FORM_CATEGORY;

  SERVICES.DELETE_FORM_CATEGORY.dynamicUrl = `${host}${endpoint}${categoryID}`;

  return sendRequest(SERVICES.DELETE_FORM_CATEGORY);
};

const deleteFormCategoryBulk = async (categoryIds: number[]) => {
  return sendRequest(SERVICES.DELETE_FORM_CATEGORY_BULK, { categoryIds });
};

const orderFormCategory = (data: FormCategoriesOrderParams) => sendRequest(SERVICES.ORDER_FORM_CATEGORY, { ...data });

export default {
  getForms,
  getForm,
  createForm,
  updateForm,
  deleteForm,
  deleteFormBulk,
  getFormCategories,
  createFormCategory,
  updateFormCategory,
  deleteFormCategory,
  deleteFormCategoryBulk,
  orderFormCategory,
  updateFormState,
  getFormTranslation,
};

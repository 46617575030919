import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import { IQueryValue, IRole } from "@ax/types";
import { usePermission } from "@ax/hooks";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    sortItems,
    filterItems,
    //sortedListStatus,
    //filterValues,
    setHoverCheck,
  } = props;

  const bulkActions: any[] = [];

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      //filterValues={filterValues}
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      filterItems={filterItems}
      isScrolling={isScrolling}
      sortItems={sortItems}
      //sortedListStatus={sortedListStatus}
      setHoverCheck={setHoverCheck}
      checkState={checkState}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: Record<string, boolean>;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  //sortedListStatus: { isAscending: boolean; sortedByName: boolean; sortedByDateCreated: boolean };
  //filterValues: Record<string, IQueryValue[]>;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  setHoverCheck: (state: boolean) => void;
}

export default BulkHeader;

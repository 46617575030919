import React from "react";

import { CheckField, TableCounter, SiteFilter, NameFilter, RoleFilter, Tooltip } from "@ax/components";
import { IQueryValue, IRole } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    totalItems,
    selectAllItems,
    isScrolling,
    sortItems,
    filterItems,
    //sortedListStatus,
    //filterValues,
    checkState,
    setHoverCheck,
  } = props;

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <Tooltip content="Select All Users" bottom>
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectAllItems}
            checked={checkState.isAllSelected || checkState.hoverCheck}
            disabled={false}
            error={false}
            setHoverCheck={setHoverCheck}
          />
        </Tooltip>
      </S.CheckHeader>
      <S.NameWrapper>Form name</S.NameWrapper>
      <S.TypeHeader>Type</S.TypeHeader>
      <S.UpdateHeader>Last update</S.UpdateHeader>
      <S.StateHeader>State</S.StateHeader>
      <S.LangHeader>Lang</S.LangHeader>
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  //sortedListStatus: { isAscending: boolean; sortedByName: boolean; sortedByDateCreated: boolean };
  //filterValues: Record<string, IQueryValue[]>;
  checkState: Record<string, boolean>;
  setHoverCheck: (state: boolean) => void;
}

export default TableHeader;

const IS_BROWSER = typeof window !== "undefined";

export type PublicApiOperators = "or" | "and" | "OR" | "AND";

export interface ConcatParams {
	baseUrl: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: Record<string, any>;
}

function isLocalStorageAvailable() {
	try {
		const test = "___local_storage_test___";
		localStorage.setItem(test, test);
		localStorage.removeItem(test);
		return true;
	} catch (e) {
		return false;
	}
}

function getToken() {
	if (!isLocalStorageAvailable()) {
		return null;
	}

	const localStorageData = localStorage.getItem("persist:app");

	// No localstorage or browser
	if (!localStorageData || !IS_BROWSER) return null;

	const appInfo = JSON.parse(localStorageData);

	let { token } = appInfo;
	token = JSON.parse(token);
	const authorization = { Authorization: `bearer ${token}` };

	return authorization;
}

function getSiteID() {
	if (!isLocalStorageAvailable()) {
		return null;
	}

	const localStorageData = localStorage.getItem("persist:root");

	// No localstorage or browser
	if (!IS_BROWSER || !localStorageData) return 1;

	const appInfo = JSON.parse(localStorageData);

	let { sites } = appInfo;
	sites = JSON.parse(sites);
	const id = sites.currentSiteInfo?.id;
	return id;
}

function getLang() {
	if (!isLocalStorageAvailable()) {
		return false;
	}

	const localStorageData = localStorage.getItem("langID");

	// No localstorage or browser
	if (!IS_BROWSER || !localStorageData) return false;

	return Number.parseInt(localStorageData);
}

/**
 * cleanPathSlashes
 * @param url A string
 * @example
 * @returns A string without duplicated slashes
 * cleanPathSlashes('url/with//duplicate///slashes//)
 * -> 'url/with/duplicate/slashes/'
 *
 */
function cleanPathSlashes(url: string) {
	return url.split("/").filter(Boolean).join("/");
}

/**
 * isValidParam
 * @param param A value for the query param
 * @returns true if the param is a valid query param
 */
function isValidParam(p: Array<string> | string | number) {
	return (
		(Array.isArray(p) && p.length > 0) ||
		typeof p === "number" ||
		(typeof p === "string" && !!p)
	);
}

/**
 * concatParams
 * @param params
 * @param params.baseUrl base url of the api
 * @param params.params object with the params to be sent
 * @returns a string with the params concatenated
 */
function concatParams({ baseUrl, params }: ConcatParams) {
	const paramsUrl = Object.keys(params)
		.map((key) => (isValidParam(params[key]) ? `${key}/${params[key]}` : ""))
		.join("/");
	return `${baseUrl}${cleanPathSlashes(paramsUrl)}`;
}

export { concatParams, getLang, getSiteID, getToken, isLocalStorageAvailable };

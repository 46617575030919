import React, { useState } from "react";
import { connect } from "react-redux";

import { CheckField, Flag, FloatingMenu, Icon, LanguageMenu, Tooltip } from "@ax/components";
import { FormContent, FormLanguage, FormState, ICheck, ILanguage } from "@ax/types";
import { formsActions } from "@ax/containers/Forms";
import { appActions } from "@ax/containers/App";
import { useModal } from "@ax/hooks";
import { getHumanLastModifiedDate } from "@ax/helpers";
import { DeleteModal, UnPublishModal } from "../../atoms";

import * as S from "./style";

const FormItem = (props: IFormItemProps): JSX.Element => {
  const {
    form,
    isSelected,
    languages,
    lang,
    onChange,
    onClick,
    deleteForm,
    toggleToast,
    updateFormState,
    createNewTranslation,
    setLanguage,
  } = props;
  const { id, title, modified, state, dataLanguages } = form;

  const [deleteAllVersions, setDeleteAllVersions] = useState(false);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isUnpublishOpen, toggleModal: toggleUnpublishModal } = useModal();

  const isTranslated = dataLanguages.length > 1;

  const handleClick = () => onClick(form.id);

  const handleOnChange = (value: ICheck) => onChange(value);

  const handleCheckClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const removeItem = async () => {
    const allFormVersions = dataLanguages.map((lang: FormLanguage) => lang.id);
    const isDeleted = deleteAllVersions ? await deleteForm(allFormVersions) : await deleteForm(id);
    if (isDeleted) {
      toggleToast("1 form deleted");
    }
    toggleDeleteModal();
  };

  const getSelectedFormLanguage = (language: ILanguage) =>
    dataLanguages.find((formLang) => formLang.language === language.id);

  const handleLanguage = (language: ILanguage) => {
    const { locale, id } = language;
    setLanguage({ locale, id });

    const selectedFormLanguage = getSelectedFormLanguage(language);

    if (selectedFormLanguage) {
      onClick(selectedFormLanguage.id);
    } else {
      createNewTranslation(true);
      onClick(form.id);
    }
  };

  const getCurrentLanguages = () => {
    const available: ILanguage[] = [];

    dataLanguages.forEach(
      (dataLang) =>
        languages &&
        languages.forEach((language: ILanguage) => {
          if (language.id === dataLang.language) {
            available.push(language);
          }
        })
    );

    return available;
  };

  const currentLanguages = getCurrentLanguages();

  const languageMenu = () => (
    <LanguageMenu
      language={lang.locale}
      availableLanguages={languages}
      setLanguage={handleLanguage}
      currentLanguages={currentLanguages}
    />
  );

  const FlagsButton = () => (
    <S.FlagsWrapper>
      {currentLanguages.slice(0, 2).map((pageLanguage, i: number) => (
        <Flag key={`${pageLanguage.language}${i}`} name={pageLanguage.locale} size="15" />
      ))}
      <span>({currentLanguages.length})</span>
    </S.FlagsWrapper>
  );

  const unpublishForm = async () => {
    const isUpdated = await updateFormState(id, "inactive");
    if (isUpdated) {
      toggleToast("1 form unpublished");
    }
    isUnpublishOpen && toggleUnpublishModal();
  };

  const publishForm = async () => {
    const isUpdated = await updateFormState(id, "active");
    if (isUpdated) {
      toggleToast("1 form published");
    }
  };

  const publishButton =
    state && state === "active"
      ? {
          label: "unpublish",
          icon: "offline",
          action: toggleUnpublishModal,
        }
      : {
          label: "publish",
          icon: "upload-pending",
          action: publishForm,
        };

  const menuOptions = [
    publishButton,
    {
      label: "delete",
      icon: "delete",
      action: () => toggleDeleteModal(),
    },
  ];

  const mainDeleteModalAction = {
    title: "Delete form",
    onClick: removeItem,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainUnpublishModalAction = {
    title: "Unpublish form",
    onClick: unpublishForm,
  };

  const secondaryUnpublishModalAction = { title: "Cancel", onClick: toggleUnpublishModal };

  const checkStatus = () => (state === "active" ? "active" : "offline");
  const getTooltip = () => (state === "active" ? "Live" : "Offline");

  return (
    <>
      <S.FormRow role="rowgroup" selected={isSelected} onClick={handleClick}>
        <S.CheckCell role="cell" onClick={handleCheckClick}>
          <CheckField name={`form-${id}`} value={id} checked={isSelected} onChange={handleOnChange} />
        </S.CheckCell>
        <S.NameCell role="cell">{title}</S.NameCell>
        <S.TypeCell role="cell"></S.TypeCell>
        <S.UpdateCell role="cell">{getHumanLastModifiedDate(modified)}</S.UpdateCell>
        <S.StateCell role="cell">
          <Tooltip content={getTooltip()}>
            <Icon name={checkStatus()} />
          </Tooltip>
        </S.StateCell>
        <S.LangCell role="cell">
          <FloatingMenu Button={FlagsButton}>{languageMenu()}</FloatingMenu>
        </S.LangCell>
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
        </S.ActionsCell>
      </S.FormRow>
      <DeleteModal
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        mainModalAction={mainDeleteModalAction}
        secondaryModalAction={secondaryDeleteModalAction}
        {...{ title, isTranslated, deleteAllVersions, setDeleteAllVersions }}
      />
      <UnPublishModal
        isOpen={isUnpublishOpen}
        toggleModal={toggleUnpublishModal}
        mainModalAction={mainUnpublishModalAction}
        secondaryModalAction={secondaryUnpublishModalAction}
        title={title}
      />
    </>
  );
};

interface IFormItemProps {
  form: FormContent;
  isSelected: boolean;
  languages: ILanguage[];
  lang: { locale: string; id: number };
  onChange: (value: ICheck) => void;
  onClick: (id: number) => void;
  deleteForm: (formID: number | number[]) => Promise<boolean>;
  toggleToast(message: string): void;
  updateFormState(formID: number, state: FormState): Promise<boolean>;
  createNewTranslation(isNewTranslation: boolean): void;
  setLanguage(lang: { locale: string; id: number | null }): void;
}

const mapDispatchToProps = {
  deleteForm: formsActions.deleteForm,
  updateFormState: formsActions.updateFormState,
  createNewTranslation: formsActions.createNewTranslation,
  setLanguage: appActions.setLanguage,
};

export default connect(null, mapDispatchToProps)(FormItem);

import React from "react";
import { Icon } from "@ax/components";

import * as S from "./style";

const Summary = (props: ISummaryProps) => {
  const { summary } = props;
  const { total, active, inactive } = summary;

  const totalString = total < 10 ? `0${total}` : total;
  const activeString = active < 10 ? `0${active}` : active;
  const inactiveString = inactive < 10 ? `0${inactive}` : inactive;

  return (
    <S.Wrapper>
      <S.Card color="rgba(219, 221, 233, 1)">
        <S.Number>{totalString}</S.Number>
        <S.Description>
          <S.IconWrapper>
            <Icon name="form" size="24" />
          </S.IconWrapper>
          <S.Text>Total Forms</S.Text>
        </S.Description>
      </S.Card>
      <S.Card color="rgba(175, 198, 40, 0.2)">
        <S.Number>{activeString}</S.Number>
        <S.Description>
          <S.IconWrapper>
            <Icon name="active" />
          </S.IconWrapper>
          <S.Text>Active Forms</S.Text>
        </S.Description>
      </S.Card>
      <S.Card color="rgba(210, 211, 219, 0.3)">
        <S.Number>{inactiveString}</S.Number>
        <S.Description>
          <S.IconWrapper>
            <Icon name="offline" />
          </S.IconWrapper>
          <S.Text>Inactive Forms</S.Text>
        </S.Description>
      </S.Card>
    </S.Wrapper>
  );
};

interface ISummaryProps {
  summary: { total: number; active: number; inactive: number };
}

export default Summary;

import React from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IDeleteModal): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    mainModalAction,
    secondaryModalAction,
    isTranslated,
    deleteAllVersions,
    setDeleteAllVersions,
    title,
  } = props;

  const options = [
    {
      title: "Delete only this version",
      name: "deletePage",
      value: false,
    },
    {
      title: "Delete all languages versions",
      name: "deleteAll",
      value: true,
    },
  ];

  const formTitle = title ? <strong>{title}</strong> : "this form";

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Form"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={isTranslated ? 285 : 240}
    >
      {isTranslated ? (
        <>
          <S.ModalContent>
            <p>
              Are you sure you want to delete {formTitle}? If you delete it, this form <strong>cannot be added</strong>
              to the pages. Also, this form has some translations associated.
            </p>
            <FieldsBehavior
              name="removeAllVersions"
              fieldType="RadioGroup"
              value={deleteAllVersions}
              options={options}
              onChange={setDeleteAllVersions}
            />
          </S.ModalContent>
        </>
      ) : (
        <S.ModalContent>
          Are you sure you want to delete {formTitle}?
          <br />
          If you delete it, this form <strong>cannot be added</strong> to the pages.
          <br />
          <strong>This action cannot be undone</strong>.
        </S.ModalContent>
      )}
    </Modal>
  );
};

const UnPublishModal = (props: IUnpublishModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, title } = props;

  const formTitle = title ? <strong>{title}</strong> : "this form";

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Unpublish Form"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={240}
    >
      <S.ModalContent>
        Are you sure you want to unpublish {formTitle}?
        <br />
        If you do it, this form <strong>cannot be added</strong> to the pages.
        <br />
        <strong>This action cannot be undone</strong>.
      </S.ModalContent>
    </Modal>
  );
};

interface IDeleteModal extends IModal {
  isTranslated: boolean;
  deleteAllVersions: boolean;
  setDeleteAllVersions: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
}

interface IUnpublishModal extends IModal {
  title?: string;
}

export { DeleteModal, UnPublishModal };

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import type { Core } from "../..";

import * as React from "react";

import {
	getGriddoDamURIWithParams,
	getDomainFromDamUrl,
	getDamIdFromDamUrl,
} from "../../utils/images";
import { useSite } from "../../hooks";

import "./styles.css";

const LAZY_CLASS_NAME = "griddo-background-image--lazy";

export interface GriddoBackgroundImageProps {
	format?: Core.ImageFormats;
	backgroundSize?: Core.ImageCropType;
	veilOpacity?: React.CSSProperties["opacity"];
	veilColor?: React.CSSProperties["color"];
	veilTransitionTime?: React.CSSProperties["animationDuration"];
	veilTransitionEasing?: React.CSSProperties["animationTimingFunction"];
	url?: string;
	src?: string;
	responsive?: Array<
		Omit<Core.ResponsiveImageProps, "breakpoint"> & { customProperty: string }
	>;
	quality?: number;
	lazyOffset?: string;
	loading?: Core.ImageLoading;
	children?: React.ReactNode | Array<React.ReactNode>;
	className?: string;
	style?: React.CSSProperties;
}

/**
 * <GriddoBackgroundImage>
 */
const GriddoBackgroundImage = React.forwardRef(
	(
		{
			children,
			format,
			backgroundSize,
			veilOpacity = 0.5,
			veilColor = "#000033",
			veilTransitionTime = "2s",
			veilTransitionEasing = "easing",
			url,
			src,
			responsive,
			className,
			style,
			quality,
			lazyOffset = "200px",
			loading,
		}: GriddoBackgroundImageProps,
		ref,
	) => {
		if (!url) {
			return null;
		}

		const { griddoDamDefaults } = useSite();
		const thisRef = React.useRef(null);
		const domain = getDomainFromDamUrl(url);

		React.useEffect(() => {
			if (!IntersectionObserver) {
				thisRef.current?.classList.remove(LAZY_CLASS_NAME);
			} else if (thisRef.current) {
				let observer = new IntersectionObserver(
					(entries) =>
						entries.forEach((entry) => {
							if (entry.isIntersecting) {
								thisRef.current?.classList.remove(LAZY_CLASS_NAME);
								observer = observer.disconnect();
							}
						}),
					{ rootMargin: `0px 0px ${lazyOffset} 0px` },
				);
				observer.observe(thisRef.current);

				return () => (observer = observer && observer.disconnect());
			}
		}, []);

		// url, imageConfig, ...props
		const backgroundImageResponsive =
			responsive &&
			Object.fromEntries(
				Object.keys(responsive).map((key) => {
					const griddoDamUrl = getGriddoDamURIWithParams({
						damId: getDamIdFromDamUrl(url),
						imageConfig: {
							...griddoDamDefaults,
							quality,
							format,
							domain,
							...responsive[key],
						},
					});
					const fullImagePath = {
						url: `url("${griddoDamUrl}")`,
						customProperty: responsive[key].customProperty,
					};
					return [key, fullImagePath];
				}),
			);

		const resimages =
			responsive &&
			Object.fromEntries(
				Object.keys(responsive).map((key, idx) => {
					return [
						backgroundImageResponsive[key].customProperty
							? `${backgroundImageResponsive[key].customProperty}`
							: `--image-${idx}`,

						backgroundImageResponsive[key].url,
					];
				}),
			);

		const allImages = src ? { "--image-default": `url(${src})` } : resimages;

		return (
			<div
				ref={thisRef}
				data-griddo="loading-ref"
				className={`${loading === "lazy" ? LAZY_CLASS_NAME : ""}`}
			>
				<div
					data-griddo="image-ref"
					ref={ref}
					className={`${className} griddo-background-image`}
					style={{
						...style,
						"--veil-opacity": `${veilColor}${Math.round(
							255 / (1 / veilOpacity),
						).toString(16)}`,
						"--veil-transition-time": veilTransitionTime,
						"--veil-transition-easing": veilTransitionEasing,
						"--background-size": backgroundSize,
						...allImages,
					}}
				>
					{children}
				</div>
			</div>
		);
	},
);

GriddoBackgroundImage.displayName = "GriddoBackgroundImage";

export { GriddoBackgroundImage };

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { EmptyState, ErrorToast, MainWrapper, TableList, Toast } from "@ax/components";
import { IQueryValue, IRootState, ISite, FormContent, ISchema, GetFormsParams, ILanguage } from "@ax/types";
import { useBulkSelection, useModal, useToast } from "@ax/hooks";
import { appActions } from "@ax/containers/App";
import { formsActions } from "@ax/containers/Forms";
import { ITEMS_PER_PAGE } from "@ax/containers/Forms/constants";
import { getFormTemplates } from "@ax/helpers";
import Summary from "./Summary";
import BulkHeader from "./BulkHeader";
import FormItem from "./FormItem";
import { TemplateModal } from "./TemplateModal";
import FormsMenu from "../FormsMenu";

import * as S from "./style";

const FormList = (props: IUserListProps): JSX.Element => {
  const {
    currentSiteInfo,
    forms,
    summary,
    lang,
    globalLangs,
    siteLanguages,
    setHistoryPush,
    addTemplate,
    getForms,
    setCurrentFormID,
    setLanguage,
  } = props;

  const firstPage = 1;
  const isSiteView = !!currentSiteInfo;
  const BASE_URL = isSiteView ? "/sites/forms" : "/forms";
  const isEmpty = !forms || forms.length === 0;
  const formTemplates = getFormTemplates();
  const availableLanguages = isSiteView ? siteLanguages : globalLangs;

  const [page, setPage] = useState(firstPage);
  const [isScrolling, setIsScrolling] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ISchema | null>(null);
  const { isOpen: isNewOpen, toggleModal: toggleNewModal } = useModal();
  const { isVisible, toggleToast, setIsVisible, state: toastState } = useToast();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const formIds = forms.map((form) => form.id);

  const {
    resetBulkSelection,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
    selectedItems,
    setHoverCheck,
  } = useBulkSelection(formIds);

  const pagination = {
    setPage,
    itemsPerPage: ITEMS_PER_PAGE,
    totalItems: summary.total,
    currPage: page,
  };

  const getParams = useCallback(() => {
    const params: GetFormsParams = {
      page,
    };

    return params;
  }, [page]);

  useEffect(() => {
    const handleGetForms = async () => {
      const params = getParams();
      await getForms(params);
    };
    handleGetForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams, lang]);

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const handleSelectAll = () => selectAllItems();

  const selectItems = () => (checkState.isAllSelected ? resetBulkSelection() : selectAllItems());

  const sortItems = async (orderPointer: IQueryValue[], isAscending: boolean) => {
    setPage(firstPage);
    /*const sortedState = getSortedListStatus(orderPointer[0].value.toString(), isAscending);
    setSortedListStatus(sortedState);
    setFiltersSelection("order", orderPointer, isAscending);*/
  };

  const filterItems = async (filterPointer: string, filtersSelected: IQueryValue[]) => {
    setPage(firstPage);
    //setFiltersSelection(filterPointer, filtersSelected);
  };

  const createNewForm = () => {
    const templateSchema = selectedTemplate ? selectedTemplate : Object.values(formTemplates)[0];
    addTemplate(templateSchema.component);
    setSelectedTemplate(null);
    setHistoryPush(`${BASE_URL}/editor/new`, true);
  };

  const handleClick = (formID: number) => {
    setCurrentFormID(formID);
    setHistoryPush(`${BASE_URL}/editor`, true);
  };

  const addNewAction = () => (Object.keys(formTemplates).length > 1 ? toggleNewModal() : createNewForm());

  const handleLanguage = (language: ILanguage) => {
    const { locale, id } = language;
    setLanguage({ locale, id });
    setPage(firstPage);
    resetBulkSelection();
  };

  const TableHeader = (
    <BulkHeader
      //filterValues={filterValues}
      showBulk={areItemsSelected(formIds)}
      selectAllItems={handleSelectAll}
      totalItems={summary.total}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      sortItems={sortItems}
      filterItems={filterItems}
      //sortedListStatus={sortedListStatus}
      setHoverCheck={setHoverCheck}
    />
  );

  const handleCloseModal = () => {
    isNewOpen && toggleNewModal();
    setSelectedTemplate(null);
  };

  const mainNewModalAction = {
    title: "Create new form",
    onClick: createNewForm,
    disabled: !selectedTemplate,
  };

  const secondaryNewModalAction = { title: "Cancel", onClick: handleCloseModal };

  const rightButtonProps = {
    label: "New",
    action: addNewAction,
  };

  const emptyProps = {
    message: "To start using forms in your site, create as many forms as you need",
    button: "New form",
    action: addNewAction,
  };

  return (
    <MainWrapper
      title="Forms"
      rightButton={rightButtonProps}
      language={lang}
      availableLanguages={availableLanguages}
      languageAction={handleLanguage}
    >
      <S.FormListWrapper ref={wrapperRef} data-testid="forms-wrapper">
        <FormsMenu />
        <S.TableListWrapper>
          <ErrorToast />
          {!isEmpty && <Summary summary={summary} />}
          {
            <TableList
              tableHeader={TableHeader}
              pagination={pagination}
              onScroll={onScroll}
              hasFixedHeader={true}
              tableRef={tableRef}
            >
              <>
                {isEmpty ? (
                  <S.EmptyWrapper data-testid="empty-wrapper">
                    <EmptyState {...emptyProps} />
                  </S.EmptyWrapper>
                ) : (
                  forms &&
                  forms.map((form) => {
                    const isItemSelected = isSelected(form.id);
                    return (
                      <FormItem
                        form={form}
                        key={form.id}
                        onClick={handleClick}
                        onChange={addToBulkSelection}
                        isSelected={isItemSelected}
                        toggleToast={toggleToast}
                        languages={availableLanguages}
                        lang={lang}
                      />
                    );
                  })
                )}
              </>
            </TableList>
          }
        </S.TableListWrapper>
      </S.FormListWrapper>
      {isNewOpen && (
        <TemplateModal
          isOpen={isNewOpen}
          toggleModal={handleCloseModal}
          templates={formTemplates}
          mainModalAction={mainNewModalAction}
          secondaryModalAction={secondaryNewModalAction}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
        />
      )}
      {isVisible && <Toast setIsVisible={setIsVisible} message={toastState} />}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteInfo: state.sites.currentSiteInfo,
  forms: state.forms.forms,
  summary: state.forms.summary,
  siteLanguages: state.sites.currentSiteLanguages,
  globalLangs: state.app.globalLangs,
  lang: state.app.lang,
});

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  addTemplate: formsActions.addTemplate,
  getForms: formsActions.getForms,
  setCurrentFormID: formsActions.setCurrentFormID,
  setLanguage: appActions.setLanguage,
};

interface IDispatchProps {
  setHistoryPush(path: string, isEditor?: boolean): Promise<void>;
  addTemplate(template: string): Promise<void>;
  getForms(params?: GetFormsParams): Promise<void>;
  setCurrentFormID(formID: number | null): void;
  setLanguage(lang: { locale: string; id: number }): void;
}

interface IProps {
  currentSiteInfo: ISite | null;
  forms: FormContent[];
  summary: { total: number; active: number; inactive: number };
  lang: { locale: string; id: number };
  siteLanguages: ILanguage[];
  globalLangs: ILanguage[];
}

export type IUserListProps = IProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(FormList);
